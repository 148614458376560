<template>
  <div>
    <template v-if="infos && infos.length">
      <span v-for="info in infos" :key="info.id">
        <el-descriptions :colon="false" :style="isHandle ? style1 : null">
          <span slot="title">
            报价单信息
            <span v-if="info.rollbackReason" style="color: #FAB007; font-size: 12px;">
              <i class="el-icon-warning-outline"></i>回退原因：{{ info.rollbackReason }}
            </span>
          </span>
          <el-descriptions-item label="报价轮次">{{ defaultFormat(info.versionNo) }}</el-descriptions-item>
          <el-descriptions-item label="报价单号">{{ defaultFormat(info.code) }}</el-descriptions-item>
          <el-descriptions-item label="创建时间">{{ formatDate(info.createTime) }}</el-descriptions-item>
          <el-descriptions-item label="交付方式">{{ defaultFormat(info.deliveryMethodDesc) }}</el-descriptions-item>
          <el-descriptions-item label="付款方式">{{ defaultFormat(info.paymentMethodDesc) }}</el-descriptions-item>
          <el-descriptions-item label="发票类型">{{ defaultFormat(info.invoiceTypeDesc) }}</el-descriptions-item>
          <el-descriptions-item label="联系人">{{ defaultFormat(info.contactName) }}</el-descriptions-item>
          <el-descriptions-item label="联系电话">{{ defaultFormat(info.contactPhone) }}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions :colon="false" :style="isHandle ? style2 : null">
          <el-descriptions-item label="报价备注">{{ defaultFormat(info.quotePriceRemark) }}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions :colon="false" :style="isHandle ? style3 : null">
          <el-descriptions-item label="报价附件">
            <div v-for="img in (info.inquireQuotePriceAnnexList || [])" :key="img.url" class="fileContainer">
              <el-link type="primary" :href="img.url" :underline="false" target="blank"><i class="el-icon-document"
                  style="color:#FF5959;margin-right:4px;"></i> {{ img.annexName
                }}</el-link>
            </div>
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions title="备件清单" :colon="false" class="device-table" :style="isHandle ? style4 : null"
          :labelStyle="{ margin: 0 }">
          <el-descriptions-item>
            <xz-table ref="table" :columns="columns" :data="info.itemList" :scroll="{ x: '100%', y: 600 }">
              <span slot="quantity" slot-scope="text, record">
                {{ text }}{{ record.unitName }}
              </span>
              <span slot="qualityAssPeriod" slot-scope="text, record">
                {{`${text || ''}${record.qualityAssPeriodUnitName || ''}` || '--' }}
              </span>
            </xz-table>
          </el-descriptions-item>
        </el-descriptions>
      </span>
    </template>
    <el-empty v-else style="height: 100%;" description="暂无数据" :image="require('@/assets/empty.png')"></el-empty>
  </div>
</template>

<script>
import xzTable from '@/components/xiaozi-ui/xz-table/src/table.vue';
import * as Moment from 'dayjs';
import { inquireQuotePriceHistoryQuotePriceList } from '@/api/inquiryForm.js';

export default {
  components: {
    xzTable,
  },
  props: {
    id: {
      type: [String, Number],
      default: '',
    },
    isHandle: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    id: {
      handler (val) {
        if (val) {
          this.getDetail();
        }
      },
      immediate: true,
    },
  },
  data () {
    return {
      columns: [
        {
          title: '备件名称',
          dataIndex: 'name',
          key: 'name',
          width: 120,
          ellipsis: true,
          customRender: (text) => {
            return text ? text : '--';
          },
        },
        {
          title: '备件型号',
          dataIndex: 'model',
          key: 'model',
          width: 120,
          ellipsis: true,
          customRender: (text) => {
            return text ? text : '--';
          },
        },
        {
          title: '备件规格',
          dataIndex: 'spec',
          key: 'spec',
          width: 120,
          ellipsis: true,
          customRender: (text) => {
            return text ? text : '--';
          },
        },
        {
          title: '兼容性',
          dataIndex: 'compatibilityRequirementDesc',
          key: 'compatibilityRequirementDesc',
          width: 120,
          ellipsis: true,
          customRender: (text) => {
            return text ? text : '--';
          },
        },
        {
          title: '质保期',
          dataIndex: 'qualityAssPeriod',
          key: 'qualityAssPeriod',
          ellipsis: true,
          width: 120,
          scopedSlots: { customRender: 'qualityAssPeriod' },
        },
        {
          title: '数量（单位）',
          dataIndex: 'quantity',
          key: 'quantity',
          width: 120,
          ellipsis: true,
          scopedSlots: { customRender: 'quantity' },
        },
        {
          title: '含税单价（元）',
          dataIndex: 'price',
          key: 'price',
          width: 120,
          ellipsis: true,
          customRender: (text) => {
            return text ? text : '--';
          },
        },
        {
          title: '金额（元）',
          dataIndex: 'rowAmount',
          key: 'rowAmount',
          width: 120,
          ellipsis: true,
          customRender: (text) => {
            return text ? text : '--';
          },
        },
        {
          title: '税率',
          dataIndex: 'taxRate',
          key: 'taxRate',
          width: 120,
          ellipsis: true,
          customRender: (text) => {
            return text ? text : '--';
          },
        },
        {
          title: '交货时间',
          dataIndex: 'plannedDeliveryDate',
          key: 'plannedDeliveryDate',
          width: 120,
          ellipsis: true,
          customRender: (text) => {
            return text ? text : '--';
          },
        },
        {
          title: '商品编码',
          dataIndex: 'code',
          key: 'code',
          width: 120,
          ellipsis: true,
          customRender: (text) => {
            return text ? text : '--';
          },
        },
      ],
      infos: [],
      style1: {
        'margin-top': '8px',
        'padding': '18px 18px 0',
        'border-bottom-left-radius': 0,
        'border-bottom-right-radius': 0,
      },
      style2: {
        'padding': '0 18px 0',
        'border-radius': 0,
      },
      style3: {
        'padding': '0 18px 0',
        'border-radius': 0,
      },
      style4: {
        'padding': '18px 18px 0',
        'border-radius': 0,
        'border-bottom-left-radius': '6px',
        'border-bottom-right-radius': '6px',
      },
    };
  },
  methods: {
    formatDate (text) {
      return text ? Moment(text).format('YYYY-MM-DD HH:mm:ss') : '';
    },
    defaultFormat (v) {
      return v || v === 0 ? v : '--';
    },
    getDetail () {
      inquireQuotePriceHistoryQuotePriceList({
        inquirePriceId: this.id,
        findNewestFlag: true,
        orderBy: [
          {
            orderByProperty: 'versionNo',
            orderByType: 'DESC',
          },
        ],
      }).then(({ body }) => {
        this.infos = body || [];

        this.infos.forEach((info) => {
          if (info.itemList?.length) {
            const footer = {
              name: '合计',
              quantity: 0,
              rowAmount: 0,
            };

            (info.itemList || []).forEach(i => {
              footer.quantity += (i.quantity || 0) * 1;
              footer.rowAmount += (i.rowAmount || 0) * 1;

              i.plannedDeliveryDate = this.formatDate(info.plannedDeliveryDate);
            });

            info.itemList.push(footer);
          }
        });
      });
    },
  },
};
</script>
<style scoped lang="scss">
/deep/.el-descriptions {
  background-color: #fff;
  border-radius: 6px;

  .el-descriptions__header {
    margin-bottom: 10px;
  }

  &.device-table {
    .el-descriptions__header {
      margin-bottom: 0px;
    }

    .ant-table-body {
      tr:last-of-type {
        background-color: #f2f4f7;
      }
    }

    .el-descriptions-item__content {
      overflow: auto;
    }
  }
}
</style>
